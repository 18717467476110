import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ROLE_MANAGER, ROLE_STUDENT, ROLE_TEACHER } from "../helpers/constants";
import { useSelector } from "react-redux";

export default function useSmartRedirection() {
  const navigate = useNavigate();
  const { currentLayout, selectedGroupId } = useSelector((x) => x.security) ?? {};
  useEffect(() => {
    switch (currentLayout) {
      case ROLE_STUDENT:
        selectedGroupId
          ? navigate(`/student/general`)
          : navigate("/student/select-group");
        break;
      case ROLE_TEACHER:
        selectedGroupId
          ? navigate(`/teacher/general`)
          : navigate("/teacher/select-group");
        break;
      case ROLE_MANAGER:
          navigate(`/manager/general`)
        break;
      default:
        navigate(`/sign-in`)
        break;
    }
  }, [currentLayout]);
}
